<script setup>
import { onMounted, ref } from "@vue/runtime-core";
import { defineProps, defineEmits } from "vue";
import { Line } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
import { useStore } from "vuex";
import Loadercomp from "@/components/loadercomp.vue";
import Swal from "sweetalert2";
const emit = defineEmits(["closeChart"]);
const store = useStore();
const data = ref(null);
const totals = ref(null);
const loaded = ref(null);
const window = ref("1d");
const props = defineProps({
    apikey: String,
});

onMounted(() => {
    Chart.register(...registerables);
    loadMetrics();
});

const options = ref({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 14,
                },
            },
        },
    },
});

const loadMetrics = () => {
    store.state.loader = true;
    fetch(`/api/org/${store.state.active_org.id}/metricsv2/${props.apikey}?window=${window.value}`)
        .then((r) => {
            return r.json();
        })
        .then((res) => {
            store.state.loader = false;
            if (res.ok) {
                data.value = res.data;
                totals.value = res.data.datasets;
                loaded.value = true;
            } else if (!res.ok) {
                Swal.fire({
                    icon: "error",
                    titleText: "!Metrics not found",
                    text: `
                   In order to view a key usage graph, you have to make requests with the api key 
                    `,
                    confirmButtonColor: "#417dd8",
                    confirmButtonText: "Continue",
                });
            }
        })
        .catch((err) => {
            console.error(err);
        });
};

const emitClose = () => {
    emit("closeChart");
};

const sideEmit = (e) => {
    if (e.target.classList.contains("timestream")) {
        emit("closeChart");
    }
};
</script>

<template>
    <Loadercomp v-if="$store.state.loader" />
    <div v-if="loaded" class="container timestream" @click="sideEmit">
        <div class="chart_wrap animate__slideInUp animate__animated">
            <div class="head_chart flex_r flex">
                <div class="select_window">
                    <label for="select-window"> Window: </label>
                    <select v-model="window" @change="loadMetrics" class="dashboard_select" name="select-window" id="">
                        <option value="1d">1 day</option>
                        <option value="7d">7 days</option>
                        <option value="30d">30 days</option>
                    </select>
                </div>
                <p @click="emitClose" class="close_chart"><i class="ri-fullscreen-exit-line"></i></p>
            </div>
            <div class="line-chart">
                <Line width="1600" height="500" :chart-options="options" :chart-data="data" />
            </div>
            <div class="totals_key_usage">
                <h3>Total By Call</h3>
                <ul>
                    <li v-for="total in totals" :key="total">
                        <span :style="{ color: total.borderColor }">{{ total.label }}</span> :
                        {{
                            total.data.reduce((a, b) => {
                                return a + b;
                            })
                        }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.container {
    @include scaffold;
    z-index: 300;
    overflow: scroll;
    .chart_wrap {
        padding: 3rem;
        width: 87%;
        background: rgb(255, 255, 255);
        margin: 0 auto;
        box-shadow: 2px 1px 20px 0px #8f8f8f;
        @include flex_col(2rem);
        @include media("<=phone-tab") {
            padding: 1.5rem;
            width: 95%;
        }
        //   .line-chart{
        //       height: !important;
        //       position: relative;
        //       canvas{
        //           height: 100%
        //       }
        //   }
        .totals_key_usage {
            @include flex_col(1rem);
            ul {
                @include flex_col(0.9rem);
                li {
                    list-style-type: none;
                    word-wrap: break-word;
                }
            }
        }
        .close_chart {
            cursor: pointer;
            i {
                font-size: 1.5rem;
            }
        }
    }
}
</style>

<script setup>
import { ref } from "@vue/reactivity";
import networks from "@/db/networks.json";
import Fade from "@/components/fade.vue";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
const store = useStore();
const arrow = ref(null);
const showModal = ref(false);
const val = ref(0);
const toggleModal = () => {
    showModal.value = !showModal.value;
    arrow.value.classList.toggle("rotate");
};

onMounted(() => {
    if (window.localStorage.getItem("network")) {
        val.value = window.localStorage.getItem("network");
    } else {
        val.value = 0;
    }
    store.state.network = networks[val.value].network;
});
const selectNetwork = (network) => {
    val.value = network._id;
    store.state.network = network.network;
    if (typeof window !== "undefined") {
        window.localStorage.setItem("network", val.value);
    }
    showModal.value = !showModal.value;
    arrow.value.classList.toggle("rotate");
};
</script>

<template>
    <div class="network_dropdown">
        <div @click="toggleModal" class="network_dropdownBtn flex">
            <div class="mainnet_symbol">
                <img class="nodrag" src="@/assets/ETH.svg" alt="" />
            </div>
            <span class="default_net">{{ networks[val].name }}</span>
            <div class="toggleDropDown">
                <img class="nodrag" ref="arrow" src="@/assets/drop-down.svg" alt="" />
            </div>
        </div>
        <Fade>
            <div class="select_modal dropdown" v-if="showModal">
                <ul class="flex flex_col">
                    <li class="flex flex_r" @click="selectNetwork(network)" v-for="network in networks" :key="network._id">
                        <p>{{ network.name }}</p>
                        <img v-if="network._id === val" src="@/assets/active.svg" alt="" />
                    </li>
                </ul>
            </div>
        </Fade>
    </div>
</template>

<style lang="scss" scoped>
.rotate {
    transform: rotateZ(-180deg);
    transition: 0.2s ease-in-out;
}
.network_dropdown {
    width: 100%;
    padding: 10px 0px;
    border-radius: 10px;
    @extend %noselect;
    cursor: pointer;
    border: 1px solid #4d4d4d;
    .network_dropdownBtn {
        justify-content: space-evenly;
        align-items: center;
    }
}
.select_modal {
    width: 19rem;
    padding: 20px 25px;
    @include media("<=phone-tab") {
        width: 17rem;
    }
    ul {
        gap: 0.5rem 0;
        li {
            align-items: center;
            cursor: pointer;
            p {
                list-style: none;
                font-size: 0.9em;
            }
        }
    }
}
</style>

<script setup>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { defineProps } from "vue";
import Linechart from "./line-chart.vue";

defineProps({
    data: Object,
});
const store = useStore();
const showChart = ref(false);

const showDetails = ref(true);
const updateKeyStatus = (e) => {
    if (e.target.value === "inactive") {
        store.state.keys[e.target.dataset.key].active = false;
    } else if (e.target.value === "active") {
        store.state.keys[e.target.dataset.key].active = true;
    }
};

const toggleChart = () => {
    showChart.value = !showChart.value;
};

const storeKeyName = (e) => {
    store.state.keys[e.target.dataset.key].name = e.target.value;
};
</script>

<template>
    <header class="flex">
        <h3>
            <i class="ri-key-2-fill"></i> {{ $store.state.keys[data.key].name.charAt(0).toUpperCase() + $store.state.keys[data.key].name.slice(1) }}
        </h3>
        <div @click="showDetails = !showDetails" :class="{ animate_arrow: showDetails }" class="btn_mono">
            <i class="ri-arrow-right-s-line"></i>
        </div>
    </header>
    <br />
    <div class="child_modal flex flex_col" :class="{ active: showDetails }">
        <div class="name_key skeleton_div">
            <div class="rivet_custom_name">
                <p>Custom name :</p>
                <input
                    class="dashboard_input_txt"
                    :data-key="data.key"
                    @input="storeKeyName"
                    :placeholder="$store.state.keys[data.key].name"
                    type="text"
                />
            </div>
            <div class="rivet_key">
                <span>Key:</span>
                <div>
                    <span class="url_copy copy"> {{ data.key.substring(0, 13) }}....{{ data.key.substr(-8) }}</span>
                    <span @click="$store.dispatch('copyToClipBoard', data.key)" class="copy_icon_svg"><i class="ri-clipboard-line"></i></span>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <div class="status">
                <p :data-key="data.key">
                    Status:
                    <select :data-key="data.key" @change="updateKeyStatus($event)" class="dashboard_select" name="toggleActive">
                        <option v-if="$store.state.keys[data.key].active" value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option v-if="!$store.state.keys[data.key].active" value="active">Active</option>
                    </select>
                </p>
            </div>
            <div class="view_usage">
                <div class="btn_mono">
                    <button @click="toggleChart" class="flex_r flex" type="button">
                        <p>View Key Usage</p>
                        <i class="ri-external-link-line"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="dashboard_key skeleton_div">
            <div v-if="$store.state.network == 'eth2'">
                <div class="rivet_key">
                    <span>REST URL:</span>
                    <div class="flex">
                        <span class="copy url_copy"
                            >https://{{ data.key.substring(0, 9) }}...{{ data.key.substr(-4) }}.{{ $store.state.network }}.rest.rivet.cloud/</span
                        >
                        <span
                            @click="$store.dispatch('copyToClipBoard', `https://${data.key}.${$store.state.network}.rest.rivet.cloud/`)"
                            class="copy_icon_svg"
                            ><i class="ri-clipboard-line"></i
                        ></span>
                    </div>
                </div>
            </div>
            <div v-else class="flex_col flex">
                <div class="rivet_key">
                    <span>RPC URL:</span>
                    <div class="flex">
                        <span class="copy url_copy"
                            >https://{{ data.key.substring(0, 9) }}...{{ data.key.substr(-4) }}.{{ $store.state.network }}.rpc.rivet.cloud/</span
                        >
                        <span
                            @click="$store.dispatch('copyToClipBoard', `https://${data.key}.${$store.state.network}.rpc.rivet.cloud/`)"
                            class="copy_icon_svg"
                            ><i class="ri-clipboard-line"></i
                        ></span>
                    </div>
                </div>
                <div class="rivet_key">
                    <span> Websockets URL:</span>
                    <div class="flex">
                        <span class="copy url_copy"
                            >wss://{{ data.key.substring(0, 9) }}...{{ data.key.substr(-4) }}.{{ $store.state.network }}.ws.rivet.cloud</span
                        >
                        <span
                            @click="$store.dispatch('copyToClipBoard', `wss://${data.key}.${$store.state.network}.ws.rivet.cloud`)"
                            class="copy_icon_svg"
                            ><i class="ri-clipboard-line"></i
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_mid ta_center btn_color">
            <button type="submit" class="button_rad">Apply Changes</button>
        </div>
        <Linechart @closeChart="toggleChart" :apikey="data.key" v-if="showChart" />
    </div>
</template>

<style lang="scss">
.flex-row {
    @include flex(center, center, 0em 4em);
    @include media("<=phone-tab") {
        @include flex_col(1rem 0);
        justify-content: flex-start;
    }
}
.active {
    max-height: 100% !important;
}
.child_modal {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
    .view_usage {
        .btn_mono {
            button {
                gap: 0 0.5rem;
                i {
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
    .name_key {
        @include flex(space-evenly, center, 1rem 0);
        @include media("<=tablet") {
            @include flex_col(1rem 0);
            align-items: flex-start;
        }
        .rivet_custom_name {
            @include flex(flex-start, center, 0 1em);
            @include media("<=tablet") {
                @include flex_col(0.3rem);
                width: 100%;
                align-items: flex-start;
            }
            input {
                width: 12rem;
                @include media("<=tablet") {
                    width: 100%;
                    border-radius: 10px;
                    border: 0.3px solid rgb(226, 226, 226);
                }
            }
        }
    }
    .rivet_key {
        @include flex(flex-start, center, 0 1em);
        @include media("<=phone-tab") {
            width: 100%;
            @include flex_col(0.5rem);
            align-items: flex-start;
        }
        > div {
            @include flex(flex-start, center, 0 1rem);
        }
        .url_copy {
            line-break: anywhere;
        }
    }
    .btn_mid {
        width: 20rem;
        margin: 0 auto;
        @include media("<=phone") {
            width: 100%;
        }
    }
}
</style>

<script setup>
import { ref } from "@vue/reactivity";
import keyElem from "@/components/dashboard/keys.vue";
import { useStore } from "vuex";
import networkDropDown from "@/components/dashboard/network_dropdown.vue";
import loadercomp from "@/components/loadercomp";
import getCookie from "@/scripts/utils/getCookie.js";
import Swal from "sweetalert2";
const key_name = ref(null);
const store = useStore();
const max = ref(40);

const createKey = () => {
    store.commit("toggleLoader");
    fetch(`/api/org/${store.state.active_org.id}/tok`, {
        method: "POST",
        body: JSON.stringify({
            active: true,
            name: key_name.value,
        }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    })
        .then((r) => r.json())
        .then((resp) => {
            let keys = store.state.keys;
            keys[resp.token_id] = { name: key_name.value, active: true };
            store.state.keys_item.push({ name: key_name.value, key: resp.token_id });
            store.commit("toggleLoader");
            Swal.fire({
                icon: "success",
                titleText: "New Key Created",
                text: `
                    
                    `,
                confirmButtonColor: "#417dd8",
                confirmButtonText: "Continue",
            });
            key_name.value = "";
        });
};

const updateKey = (e) => {
    store.commit("toggleLoader");
    let key = e.target.dataset.key;
    let key_data = store.state.keys[key];
    fetch(`/api/org/${store.state.active_org.id}/tok/${key}`, {
        method: "PUT",
        body: JSON.stringify({ active: key_data.active, name: key_data.name }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    }).then((r) => {
        store.commit("toggleLoader");
        if (!r.ok) {
            Swal.fire({
                icon: "error",
                titleText: "Updated!",
                text: `
                    
                    `,
                confirmButtonColor: "#417dd8",
                confirmButtonText: "Try again?",
            });
        } else {
            Swal.fire({
                icon: "success",
                titleText: "Updated!",
                text: `
                    
                    `,
                confirmButtonColor: "#417dd8",
                confirmButtonText: "Continue",
            });
        }
    });
};
</script>

<template>
    <div class="overview_wrap dashboard_root flex flex_col">
        <loadercomp v-if="store.state.loader" />
        <div class="overview_top">
            <networkDropDown />
        </div>
        <form @submit.prevent="createKey" class="create_key">
            <div class="skeleton_div divette flex_r flex">
                <p>Create New key:</p>
                <div class="create_key_input">
                    <input
                        class="dashboard_input_txt"
                        :maxlength="max"
                        required
                        v-model="key_name"
                        type="text"
                        placeholder="Enter New Key Name (max 30 characters)"
                    />
                </div>
            </div>
            <div class="btn_color btn_mid">
                <button type="submit">Add key</button>
            </div>
        </form>
        <div v-if="$store.state.active_org" class="array_form flex_col flex">
            <form @submit.prevent="updateKey" :data-key="key.key" v-for="key in store.state.keys_item" :key="key" class="second_">
                <keyElem :data="key" />
            </form>
        </div>
        <div v-else class="ajax_loader ta_center">
            <img width="40" src="https://res.cloudinary.com/ferventdev/image/upload/v1655149185/rivet.cloud/1488_t1b6v8.gif" alt="" />
        </div>
    </div>
</template>

<style lang="scss">
.overview_wrap {
    .overview_top {
        width: 15rem;
        margin: 0 auto;
    }
    .array_form {
        gap: 1.7rem;
        header {
            background-color: #f5f5f5;
            border-radius: 10px;
            padding: 20px 20px;
        }
        .second_ {
            .dashboard_key {
                > div {
                    gap: 1.5em 0;
                }
            }
            .child_modal {
                gap: 1.5rem;
                transition: 0.3s ease-in-out;
            }
        }
    }
    .create_key {
        @include flex(space-evenly, center, 0 1rem);
        @include media("<=tablet") {
            @include flex_col(1.5rem 0);
        }
        &_input {
            width: 60%;
            input {
                padding: 0.5rem 0;
            }
            @include media("<=phone-tab") {
                width: 100%;
            }
        }
        .divette {
            width: 60%;
            justify-content: flex-start;
            gap: 2rem;
            p {
                @include media("<=phone-tab") {
                    display: none;
                }
            }
            @include media("<=tablet") {
                width: 100%;
            }
        }
        .btn_mid {
            width: 30%;
            @include media("<=tablet") {
                width: 100%;
            }
        }
    }
}
i {
    font-size: 1.2em;
}
.ri-arrow-right-s-line {
    font-size: 2em;
}
.animate_arrow {
    transform: rotateZ(90deg);
    transition: 0.2s ease-in-out;
}
</style>
